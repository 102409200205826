import { ethers } from 'ethers';
import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import Swal from 'sweetalert2';

export default class RedeemCode extends Component<RouteComponentProps<{code:string}>,any> {
  state = {
    signature: "",
    hash: '',
    coupon: [],
    error : false
  };
  STATE = ["Invalid", "Active", "Redeemed"];

  redeemCoupon = async (e) => {
    e.preventDefault();
    if (window.wallet) {
      const A = await window.couponDappInstance
        .connect(window.wallet)
        .populateTransaction.redeemCoupon(
          this.state.hash,
          this.state.signature
        );

      console.log("call : ", A);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        html: `<p>You will not be able to undo this action!</p>
                      <h1 style={{fontStyle:"bold"}}> Value : ${
                        A.value ? ethers.utils.formatEther(A?.value) : "0"
                      } </h1>
                      <small> To : ${A.to} </small><br/><small> From : ${
          A.from
        } ES </small>
                      <p> gasFee : ${A?.gasPrice || "0"} </p>`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancel!",
        confirmButtonText: "Yes, do it!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return window.couponDappInstance
            .connect(window.wallet)
            .redeemCoupon(this.state.hash, this.state.signature)
            .then(async (res) => {
              await res.wait();

              Swal.fire({
                title: "Good job!",
                icon: "success",
                html: `<p>You have successfully redeem  Coupon</p><br/>Transaction Hash<a>${res.hash}</a><br/>Coupon ES has been added into your wallet `,
              });
            })
            .catch(async () => {
              const add = window.wallet.address
                ? window.wallet.address
                : await window.wallet.getAddress();
              const x = new ethers.VoidSigner(add, window.providerESN);
              try {
                await window.couponDappInstance
                  .connect(x)
                  .estimateGas.redeemCoupon(
                    this.state.hash,
                    this.state.signature
                  );
              } catch (e) {
                console.log("Error is : ", e);
                Swal.fire("Oops...!", `${e}`, "error");
              }
            });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Connect to wallet ...",
      });
    }

    this.setState({
      showNewCouponTransactionModal: false,
    });
  };

  componentDidMount = async () => {
    const data = this.props.match.params.code;
    try {
      const x = ethers.utils.RLP.decode(data);
      const C = await window.couponDappInstance.coupons(x[0]);
      this.setState({
        hash: x[0],
        signature: x[1],
        coupon: [ethers.utils.formatEther(C[0]), C[1], C[2]],
      });
    } catch (e) {
      console.log("Error : ", e);
      this.setState({ error: true });
    }
  }

  render() {
    return (
      <section className="com-sec-hed">
        <Card className="com-top-space"> 
            <h1>Redeem Your Coupon</h1>
            {this.state.error?<div
              className="card text-center m-auto"
              style={{ width: "25rem", border: "2px dashed gray" }}
            >
              <img
                className="m-auto"
                width="100"
                src="https://assets.coingecko.com/coins/images/6031/large/era_swap.PNG"
                alt="logo"
              />
              <div className="card-body">
                <h1 className="card-title">Oops ... !</h1>
                <p className="card-text">Coupon not found.</p>
                <p className="card-text">
                  Coupon code is Invalid
                </p>
              </div>
            </div>:

      <div
        className="card text-center m-auto"
        style={{ width: "25rem", border: "2px dashed gray" }}
      >
        <img
          className="m-auto"
          width="100"
          src="https://assets.coingecko.com/coins/images/6031/large/era_swap.PNG"
          alt="logo"
        />
        <div className="card-body">
          <h1 className="card-title">{this.state.coupon[0] || 'Please wait ...'} ES</h1>
          <p className="card-text">You can redeem this coupon.</p>
          <p className="card-text">
            This Coupon has been generated By {this.state.coupon[2]}
          </p>
          {this.state.coupon[1] === 1 ? (
            <button
              onClick={this.redeemCoupon}
              className="btn btn-primary"
            >
              Redeem Now
            </button>
          ) : null}
          <hr />
          <p className="card-text">
            <small className="text-muted">
              Coupon is {this.STATE[this.state.coupon[1]]}
            </small>
          </p>
        </div>
      </div>}
    </Card >
      </section>
    
    )
  }
}
