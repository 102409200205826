import { ethers } from "ethers";
import { CouponDappCouponDappFactory } from "./CouponDappCouponDappFactory";

// const nodeUrl = process.env.REACT_APP_NODE_URL || 'https://node2.testnet.eraswap.network/';

window.provider = new ethers.providers.JsonRpcProvider(
  "https://mainnet.eraswap.network"
);
window.providerESN = new ethers.providers.JsonRpcProvider(
  "https://mainnet.eraswap.network"
);
// window.provider = new CustomProvider("mainnet");

window.couponDappInstance = CouponDappCouponDappFactory.connect(
  "0xb38df7472cFF83dC17567A76f30A02055F3a37b2",
  window.providerESN
);

// export const BetInst = BetFactory.connect(addresses.Bet, providerESN);

// export const providerESN = new ethers.providers.JsonRpcProvider(nodeUrl);

console.log("custom provider", window.providerESN);
